import React from "react"
import Layout from "../../components/layout"

const IndexPage = () => (
  <Layout>
		<h1>Schedule demo</h1>
		<p>Thank you. Your form submission has been sent. Someone will reach out to you within 1 business day.</p>
  </Layout>
)

export default IndexPage
